exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-loop-js": () => import("./../../../src/pages/loop.js" /* webpackChunkName: "component---src-pages-loop-js" */),
  "component---src-pages-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-the-void-js": () => import("./../../../src/pages/the-void.js" /* webpackChunkName: "component---src-pages-the-void-js" */),
  "component---src-pages-things-bordir-js": () => import("./../../../src/pages/things/bordir.js" /* webpackChunkName: "component---src-pages-things-bordir-js" */),
  "component---src-pages-things-js": () => import("./../../../src/pages/things.js" /* webpackChunkName: "component---src-pages-things-js" */),
  "component---src-pages-things-micro-swimmers-js": () => import("./../../../src/pages/things/micro-swimmers.js" /* webpackChunkName: "component---src-pages-things-micro-swimmers-js" */),
  "component---src-pages-words-js": () => import("./../../../src/pages/words.js" /* webpackChunkName: "component---src-pages-words-js" */),
  "component---src-pages-words-two-kids-js": () => import("./../../../src/pages/words/two-kids.js" /* webpackChunkName: "component---src-pages-words-two-kids-js" */)
}

